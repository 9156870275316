import styled from "@emotion/styled"
import React from "react"
import { H1 } from "../header"
import { Page } from "../page"
import { PostsList } from "../posts-list"

const Description = styled.p`
  margin-bottom: 0;
`

export const ResultsPage = ({
  posts,
  tagOrCategoryName,
  tagOrCategoryDescription,
  taxonomyType,
}) => {
  return (
    <Page
      seoTitle={`${tagOrCategoryName} posts`}
      seoDescription={tagOrCategoryDescription}
      header={() => (
        <>
          <H1>
            {taxonomyType && <em>{taxonomyType}: </em>}
            {tagOrCategoryName}
          </H1>
          {tagOrCategoryDescription && (
            <Description>{tagOrCategoryDescription}</Description>
          )}
        </>
      )}
    >
      <PostsList posts={posts} />
    </Page>
  )
}
